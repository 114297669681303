export const preloadImage = src => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve()
    }
    image.onerror = reject
    image.src = src
  })
}
