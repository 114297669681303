<template lang="pug">
  u-animate-container
    u-animate(
      name="fadeIn"
      delay="0s"
      :offset="0"
      :iteration="1"
      duration="1.5s"
      animateClass="animated"
      :begin="false")
      slot
</template>

<script>
export default {
  name: 'VAnimate'
}
</script>

<style>
</style>
