import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN'
const SET_INVITATION_ID = 'SET_INVITATION_ID'
const INV_KEY = 'inv_id'

export default new Vuex.Store({
  state: {
    isMenuOpened: false,
    invitationId: localStorage.getItem(INV_KEY) || null
  },

  getters: {
    invitationId: (state) => state.invitationId,
    isMenuOpened: (state) => state.isMenuOpened
  },

  mutations: {
    [SET_IS_MENU_OPEN] (state, data) {
      state.isMenuOpened = data
    },

    [SET_INVITATION_ID] (state, data) {
      state.invitationId = data
    }
  },

  actions: {
    openMenu ({ commit }) {
      commit(SET_IS_MENU_OPEN, true)
      document.body.style.overflow = 'hidden'
    },

    closeMenu ({ commit }) {
      commit(SET_IS_MENU_OPEN, false)
      document.body.style.overflow = 'auto'
    },

    setInvitationId ({ commit }, data) {
      commit(SET_INVITATION_ID, data)
      localStorage.setItem(INV_KEY, data)
    }
  }
})
