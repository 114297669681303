export default {
  base: {
    yes: 'Да',
    no: 'Нет',
    children: 'Дети',
    and: 'и',
    dear: 'Дорогие',
    dear_boy: 'Дорогой',
    dear_girl: 'Дорогая',
    you: 'тебя',
    you_2: 'вас'
  },
  intro: {
    text_1: 'Любите друг друга',
    text_2: 'Иоанна 13:34'
  },
  menu: {
    main: 'главная',
    photos: 'фотографии',
    colors: 'палитра цветов',
    messanger: 'Telegram'
  },
  info: {
    dear: 'Дорогие',
    you: 'вас',
    text_1: 'С радостью приглашаем {you}<br> на праздник, посвященный<br> созданию нашей семьи,<br> который состоится:',
    text_2: '26 февраля 2022 года',
    text_4: 'И мы не представляем этот день<br> без вас - самых близких и<br> дорогих нам людей!',
    text_5: 'Для вашего удобства мы разработали<br> сайт с подробной информацией<br> о нашем празднике.',
    text_7: 'Приятного просмотра :)'
  },
  address: {
    text_1: 'ВЕНЧАНИЕ',
    text_2: 'г. Минск, ул.Гурского, 48<br> Большой зал церкви «Благодать»<br> Начало в 13:00',
    text_3: 'ПОСМОТРЕТЬ НА КАРТЕ',
    text_4: 'БАНКЕТ',
    text_5: 'г. Минск, ул.Гурского, 48<br> Малый зал церкви «Благодать»<br> Начало в 14:30'
  },
  rsvp: {
    text_1: 'ВЕНЧАНИЕ',
    text_2: 'БАНКЕТ',
    text_3: 'сможете ли Вы присоединиться<br> к празднованию вместе с нами?',
    text_4: 'ЕСЛИ БУДЕТЕ С ДЕТЬМИ, ПОЖАЛУЙСТА,<br> УКАЖИТЕ НУЖНОЕ КОЛИЧЕСТВО МЕСТ',
    text_5: 'НА КАКИХ ЧАСТЯХ МЕРОПРИЯТИЯ<br> ПЛАНИРУЕТЕ ПРИСУТСТВОВАТЬ?',
    text_6: 'Просьба сообщить о присутствии<br> до 16 февраля 2022 года'
  },
  timing: {
    caption: 'ТАЙМИНГ ДНЯ',
    text_1: 'Венчание',
    text_2: 'Праздничный банкет',
    text_3: 'Перерыв',
    text_4: 'Продолжение программы',
    text_5: 'Нappy end'
  },
  present: {
    text_1: 'Мы не хотим обременять вас выбором подарка,<br> поэтому будем рады вашему вкладу в бюджет<br> нашей молодой семьи:',
    text_2: 'реквизиты',
    text_3: 'ALIAKSANDR KIBAK',
    text_4: 'Belgazprombank',
    text_5: '5299 2299 2297 6554',
    text_6: '08/25',
    text_7: 'DZIYANA DAVEDZKO',
    text_9: 'Belinvestbank',
    text_10: '5578 8433 7016 5668',
    text_11: '08/23',
    text_12: 'BY58BLBB30141200000003480770'
  },
  countdown: {
    days: 'дней',
    hours: 'часов',
    minutes: 'минут',
    seconds: 'секунд',
    ps: '... и мы будем женаты!'
  },
  messanger: {
    text_1: 'Для быстрого обмена<br> информацией, фото и видео<br> между нашими гостями<br> мы создали группу<br> в Telegram.',
    text_2: 'добавиться'
  },
  no_invitation: {
    text_1: 'чтобы открыть приглашение<br> воспользуйтесь своей уникальной<br> ссылкой =)'
  },
  colors: {
    text_1: 'БУДЕМ ПРИЗНАТЕЛЬНЫ, ЕСЛИ ВЫ<br> ПОДДЕРЖиТЕ ЦВЕТОВУЮ ГАММУ<br> НАШЕГО ПРАЗДНИКА'
  },
  photos: {
    text_1: 'здесь будут наши<br> свадебные фото и видео'
  }
}
