<template lang="pug">
  #app
    desktop-wrapper
      template(slot="noscroll")
        app-menu
        intro-screen(
          v-if="showIntro && hasInvitation"
          @intro="showIntro = false")
      app-header(v-if="hasInvitation")
      router-view
</template>

<script>
import { preloadImage } from '@/util/index.js'

import DesktopWrapper from '@/components/common/DesktopWrapper'
import AppMenu from '@/components/common/AppMenu'
import AppHeader from '@/components/common/AppHeader'
import IntroScreen from '@/components/common/IntroScreen'

export default {
  name: 'App',

  components: {
    DesktopWrapper,
    AppHeader,
    IntroScreen,
    AppMenu
  },

  computed: {
    hasInvitation () {
      return !!this.$store.getters.invitationId
    }
  },

  data: () => ({
    showIntro: true
  }),

  mounted () {
    const imgs = [
      require('@/assets/img/main.jpg'),
      require('@/assets/img/colors-bg.jpg'),
      require('@/assets/img/photos-bg.jpg'),
      require('@/assets/img/second.jpg')
    ]
    for (const img of imgs) {
      preloadImage(img)
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/main.scss';
</style>
