import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWow from 'vue-wow'
import VueI18n from 'vue-i18n'
import vClickOutside from 'v-click-outside'
import messages from '@/i18n/index.js'

import VAnimate from '@/components/global/VAnimate'
import VDropdown from '@/components/global/VDropdown'

import HeartIcon from '@/components/svg/HeartIcon'
import InvitoIcon from '@/components/svg/InvitoIcon'
import TelegramIcon from '@/components/svg/TelegramIcon'
import MenuIcon from '@/components/svg/MenuIcon'
import FlowerBranchIcon from '@/components/svg/FlowerBranchIcon'

Vue.use(VueWow)
Vue.use(VueI18n)
Vue.use(vClickOutside)

Vue.component('VAnimate', VAnimate)
Vue.component('VDropdown', VDropdown)
Vue.component('HeartIcon', HeartIcon)
Vue.component('InvitoIcon', InvitoIcon)
Vue.component('TelegramIcon', TelegramIcon)
Vue.component('MenuIcon', MenuIcon)
Vue.component('FlowerBranchIcon', FlowerBranchIcon)

const i18n = new VueI18n({
  locale: 'ru',
  messages
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
